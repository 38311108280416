<template>
	<div
		class="mt-30 cartel"
	>
		<div class="flex-row">
			<div
				class=" box-list"
			>
				<div
					class="bg-white radius-20 mb-30"
				>
					<div class=" flex-row justify-space-between pa-20 under-line">

						<div
							v-if="is_notice"
						>{{ item_bbs.post_title }}</div>
						<div
							v-else
							class="flex-row"
						>
							<span  class="inline-block icon icon-30 color-black mr-10">
								<img
									:src="$request.upload_url(item_bbs.post_member_profle_nft_card_img_url)"
									@error="$bus.$emit('onErrorProfileImage', $event)"
								/>
							</span>
							<div class="flex-column justify-center " style="">{{  item_bbs.post_member_nickname }}</div>
						</div>
						<div class="color-gray">
							{{ getTimeStory('item_bbs', item_bbs.post_registdate)}}
							<button
								class="post_set"
								@click="onMore(item, index)"
							><span class="hide">설정</span></button>
						</div>
					</div>

					<div>
						<carousel
							:perPage="1"
						>
							<slide
								v-for="(file, f_index) in files"
								:key="'file' + f_index"
								class="photo_collage_view bg-black "
								style="min-height: 240px"
							>
								<div
									v-if="file.post_appendix_file_div_code.indexOf('CA01000001') > -1"
									class="flex-column justify-center full-height position-relative"
								>
									<div
									>
										<img
											:src="$request.upload_url(file.post_file_url)"
											style="max-width: 100%; max-height: 350px;"
											@error="$bus.$emit('onErrorImage', $event)"
										/>

									</div>
									<v-icon
										@click="toImage(file)"
										class="position-absolute color-white pa-3 box"
										style="top: 10px; right: 10px"
									>mdi-arrow-expand-all</v-icon>
								</div>
								<video
									v-if="file.post_appendix_file_div_code.indexOf('CA01000002') > -1"

									controls
									style="object-fit: cover; max-height: 350px"
								>
									<source
										:src="file.post_file_url"
									>
								</video>
								<div
									v-if="file.post_appendix_file_div_code.indexOf('CA01000003') > -1"
									class="flex-column " style="position: static !important;"
								>
									<figure class="audio_wrap" style="height: 300px;">
										<div class="audio_bg">
											<span class="hide">사운드배경이미지</span>
										</div>
									</figure>
									<div style="width: 480px; overflow: hidden; margin: 0 auto">
										<video
											controls
											style="width: 100%; height: 50px; padding: 10px"
										>
											<source
												:src="file.post_file_url"
											>
										</video>
									</div>
								</div>
							</slide>
						</carousel>

						<div
							class="pa-20 text-left"
							style="min-height: 240px"
						>
							{{  item_bbs.post_contents }}
						</div>
					</div>

					<mafia_vote
						v-if="item_bbs.vote_fg == 'Y'"
						:cartel="item_cartel"
						:vote="item_bbs.vote"
						:bbs="item_bbs"
						:user="user"

						type="vote"
						@click="item_bbs.vote_fg = 'N'; getBbs()"
					></mafia_vote>

					<!-- 공감하기 -->
					<mafia_emoji
						v-if="item_bbs.bulletin_number"
						:cartel="item_cartel"
						:bbs="item_bbs"
						:user="user"
						:list="item_bbs.sympaty_list"

					></mafia_emoji>
				</div>
			</div>

			<CartelEtc
				:user="user"
				:cartel_info="item_cartel"
				:bbs_info="item_bbs"
			></CartelEtc>
		</div>

		<mafia098
			v-if="is_098"
			:cartel="item_cartel"
			:user="user"
			:friend="item_bbs"

			@cancel="is_098 = false"
			style="position: fixed; left: 0; top: 0; width: 100%; height: 100%; z-index: 9999; background-color: white"
		></mafia098>

		<mafia064
			v-if="is_more"
			@cancel="is_more = false"
			@move="mount"
			@deleteBbs="deleteBbs"
			@modify="toModify"

			:user="user"
			:item_bbs="item_bbs"
			style="z-index: 1003"
		>
		</mafia064>
	</div>
</template>

<script>
	import mafia_vote from '@/view/Cartel/mafiaVote'

	import { Carousel, Slide } from 'vue-carousel';
	import mafia_emoji from '@/view/Cartel/mafiaEmoji'
	import mafia064 from "@/view/Cartel/mafia064";
	import mafia098 from '@/view/Cartel/mafia098'
	import CartelEtc from "@/view/Cartel/CartelEtc";

	export default {
		name: 'mafia058'
		, props: ['user']
		, components: {CartelEtc, mafia064, mafia_vote, mafia_emoji, Carousel, Slide, mafia098}
		, data: function(){
			return {
				program: {
					name: '게시물 상세'
					, title: '게시물 상세'
					, not_footer: true
					, not_header: true
					, type: 'cartel_sub'
					, from: this.$route.params.from
					, bg_contents: 'bg-gray01'
				}
				, item_cartel: {}
				, item_board_config: {}
				, item_bbs: {
					post_registdate: ''
					, vote_fg: 'N'
				}
				, items_comment: []
				, rows: 1
				, item_comment: {
					cartel_id: this.$route.params.idx
					, bbs_code: this.$route.params.code
					, bbs_id: this.$route.params.bbs_id
					, user_id: this.user.id
					, user_nick: this.user.nick
					, user_img_src: this.user.img_src
					, upper_id: ''
					, upper_nick: ''
					, content: ''
				}
				, is_emoji: false
				, is_more: false
				, is_098: false
				, item_search: this.$storage.init({
					page_number: this.$language.base.page_number
					, pagerecnum: this.$language.base.pagerecnum
				})
			}
		}
		, computed: {
			text_title: function(){

				let t = this.item_bbs.nick

				if(this.$route.params.code == 'CA00700001'){
					t = this.item_bbs.title
				}

				return t
			}
			, is_notice: function(){
				let t = false
				if(this.$route.params.code == 'CA00700001'){
					t = true
				}
				return t
			}
			, video: function(){
				let t = []
				if(this.item_bbs.video){
					if(this.item_bbs.video.length > 0){
						t = this.item_bbs.video
					}
				}
				return t

			}
			, files: function(){
				let t = []
				if(this.item_bbs.post_file_list){
					if(this.item_bbs.post_file_list.length > 0){
						t = this.item_bbs.post_file_list
					}
				}
				return t

			}
			, sound: function(){
				let t = []
				if(this.item_bbs.sound){
					if(this.item_bbs.sound.length > 0){
						t = this.item_bbs.sound
					}
				}
				return t

			}
			, is_friend: function(){
				let t = false

				return t
			}
			, list_comment: function(){
				return this.items_comment.filter(function(item){
					if(item.blok_member_fg == 'Y'){
						item.is_block = true
					}
					return item
				})
			}
		}
		, methods: {

			getCartel: async function(){
				try{
					this.$bus.$emit('on', true)
					const result = await this.$request.init({
						method: 'post'
						, url: this.$api_url.api_path.get_cartel_info
						, data: {
							member_number: this.user.member_number
							, cartl_number: this.$route.params.idx
						}
						, type: true
					})

					if(result.success){
						this.item_cartel = result.data
					}else{
						throw result.message
					}
				}catch (e) {
					console.log(e)
					this.$bus.$emit('notify', { type: 'error', message: e})
				}finally {
					this.$bus.$emit('on', false)
				}
			}
			,getBoardConfig: async function(){
				try{
					this.$bus.$emit('on', true)
					const result = await this.$request.init({
						method: 'post'
						, url: this.$api_url.api_path.get_board_config
						, data: {
							member_number: this.user.member_number
							, cartl_number: this.$route.params.idx
							, board_number: this.$route.params.b_id
						}
						, type: true
					})

					if(result.success){
						this.item_board_config = result.data
						await this.getBbs()
						// await this.getBbsComment()
					}else{
						if(result.code == 'E001201021'){
							this.$bus.$emit('notify', { type: 'error', message: result.message})
							this.$router.back()
						}
						throw result.message
					}
				}catch (e) {
					console.log(e)
					this.$bus.$emit('notify', { type: 'error', message: e})
				}finally {
					this.$bus.$emit('on', false)
				}
			}

			, getBbs: async function(){
				try{
					this.$bus.$emit('on', true)

					let url = this.$api_url.api_path.get_cartel_bbs_info
					if(this.$route.params.code == 'CA00700001'){
						url = this.$api_url.api_path.get_cartel_notice_info
					}
					const result = await this.$request.init({
						method: 'post'
						, url: url
						, data: {
							member_number: this.user.member_number
							, cartl_number: this.$route.params.idx
							, board_number: this.$route.params.b_id
							, bulletin_number: this.$route.params.bbs_id
						}
						, type: true
					})

					if(result.success){
						this.item_bbs = result.data
						this.$set(this.item_bbs, 'board_number', this.$route.params.b_id)
						this.item_bbs.vote = result.data.vote_info
						this.item_bbs.vote.vote_participation_people_count = result.data.vote_participation_people_count
						this.item_bbs.vote.time = result.data.vote_info.vote_end_daytime
						this.item_bbs.vote.items = result.data.vote_list

						this.items_comment = result.data.comment_list
					}else{
						throw result.message
					}
				}catch (e) {
					console.log(e)
					this.$bus.$emit('notify', { type: 'error', message: e})
				}finally {
					this.$bus.$emit('on', false)
				}
			}
			, getBbsComment: async function(){
				try{
					this.$bus.$emit('on', true)
					const result = await this.$request.init({
						method: 'post'
						, url: this.$api_url.api_path.get_bbs_comment_list
						, data: {
							member_number: this.user.member_number
							, cartl_number: this.$route.params.idx
							, board_number: this.$route.params.b_id
							, bulletin_number: this.$route.params.bbs_id
							, page_number: this.item_search.page_number
							, pagerecnum: this.item_search.pagerecnum
						}
						, type: true
					})

					if(result.success){
						this.items_comment = result.data.comment_list
					}else{
						throw result.message
					}
				}catch (e) {
					console.log(e)
					this.$bus.$emit('notify', { type: 'error', message: e})
				}finally {
					this.$bus.$emit('on', false)
				}
			}
			, postComment: async function(){
				try{
					if(!this.item_comment.content || this.item_comment.content == ''){
						throw '댓글을 입력하세요'
					}
					this.$bus.$emit('on', true)
					const result = await this.$request.init({
						method: 'post'
						, url: this.$api_url.api_path.post_comment
						, data: new Object(this.item_comment)
						, name: 'postComment'
					})

					if(result.success){
						if(this.item_comment.upper_id){
							for(let c of this.items_comment){
								if(c.id == this.item_comment.upper_id){
									console.log('c', c)
									if(!c.reply){
										c.reply = []
									}
									c.reply.push(result.data)
								}
							}
						}else{
							this.items_comment.unshift(result.data)
						}
						this.item_comment = {
							cartel_id: this.$route.params.idx
							, bbs_code: this.$route.params.code
							, bbs_id: this.$route.params.bbs_id
							, user_id: this.user.id
							, user_nick: this.user.nick
							, user_img_src: this.user.img_src
							, upper_id: ''
							, upper_nick: ''
							, content: ''
						}
					}else{
						throw result.message
					}
				}catch (e) {
					console.log(e)
					this.$bus.$emit('notify', { type: 'error', message: e})
				}finally {
					this.$bus.$emit('on', false)
				}
			}
			, clearReply: function(){
				this.item_comment.upper_id = ''
				this.item_comment.upper_nick = ''
			}
			, setReply: function(comment){
				this.item_comment.upper_id = comment.upper_id
				this.item_comment.upper_nick = comment.user_nick
				//this.item_comment.content = '@' + comment.user_nick + ' '
			}
			, getTimeStory: function(type, created_at){
				console.log(type, created_at)
				return this.$date.getTimeStory(created_at)
			}
			, setFile: function(e){
				console.log(e)

				const reader = new FileReader()
				let self = this
				reader.onload = function(e){
					self.$set(self.item_comment, 'img_src', e.target.result)
				}
				reader.readAsDataURL(e.target.files[0])
			}
			, mount: async function(){

				await this.getCartel()
				await this.getBoardConfig()

				this.is_more = false
			}

			, deleteBbs: async function(){
				this.$emit('goBack')
			}
			, goBack: function(){
				if(this.$route.params.code == 'CA00700001'){
					this.$bus.$emit('to', {name: 'mafia127', params: { idx: this.$route.params.idx, code: this.$route.params.code, b_id: this.$route.params.b_id}})
				}else{
					this.$emit('goBack')
				}
			}
			, toComment: function(){
				this.$emit('to', { name: 'mafiaCommentList', params: { idx: this.$route.params.idx, code: this.$route.params.code, bbs_id: this.$route.params.bbs_id}})
			}
			, toModify: function(){
				this.$bus.$emit('to', {name: 'mafia1272', params: { idx: this.$route.params.idx, code: this.$route.params.code, b_id: this.$route.params.b_id}})
			}
			, toImage: function(file){
				this.$bus.$emit('viewer', file)
			}

			, onMore: function(){
				this.is_more = true
			}
		}
		,async created() {
			this.$bus.$emit('onLoad', this.program)
			await this.mount()
		}
		, watch: {
			'item_comment.content':{
				deep: true
				, handler: function(call){
					console.log('call', call)
					/*
					if(this.item_comment.upper_id){

						if(this.item_comment.upper_nick != call.slice(1, this.item_comment.upper_nick.length + 1)){
							this.item_comment.upper_id = ''
							this.item_comment.upper_nick = ''
						}
					}else{
						this.item_comment.upper_nick = ''
					}
					*/
				}
			}
			, $route(to, from){
				this.mount()
				console.log(to, from)
			}
		}
	}
</script>

<style>
.hashtag { font-weight: normal !important;}
.VueCarousel-dot-container { margin-top: 0px !important;}
.VueCarousel-dot-container button { margin-top: 0px !important; }

.bright_radio input[type="checkbox"] { overflow: hidden; position: absolute; margin: 0; padding: 0; clip: rect(0, 0, 0, 0); width: 1px; height: 1px; border: 0; }
.bright_radio input[type="checkbox"] + label { padding-left: 34px; line-height: 24px; font-size: 14px; }
.bright_radio input[type="checkbox"] + label:before { content: ''; position: absolute; top: 0; left: 0; width: 24px; height: 24px; border: 1px solid var(--light-Gray02); box-sizing: border-box;}
.bright_radio input[type="checkbox"]:checked  + label:before { border: 1px solid var(--blue02);}
.bright_radio input[type="checkbox"]:checked  + label:after { content: ''; position: absolute; top: 6px; left: 6px; width: 12px; height: 12px; background: var(--blue02); }
.bright_radio input[type="checkbox"]:disabled + label:before { border: 1px solid var(--light-Gray02); }
.bright_radio input[type="checkbox"]:disabled + label:after { content: ''; position: absolute; top: 6px; left: 6px; width: 12px; height: 12px; background: var(--light-Gray02); border-radius: 12px; }
</style>
<template>
	<div class="post_conunt_view clear">
		<div class="emotion_add">
			<button
				@click="is_emoji = !is_emoji"
				class="emotion_main_btn" aria-label="표정짓기"></button>
			<!-- 공감영역 -->
			<div
				v-if="is_emoji"
				class="emotion_view"
			>
				<div class="emotion_select_view">
					<div class="list">
						<!-- 선택된 표정의 버튼에는 on 클래스 추가 -->
						<button
							v-for="(emoji, index) in icons_emoji"
							:key="'emoji_icon_' + index"
							type="button" class="btn_emotionitem" data-emotion-type="best"
							@click="postEmoji(emoji)"
						>
							<span class="faceicon_30  em_select_btn" :class="'f_' + emoji.type + '_30'">{{  emoji.txt }}</span>
						</button>
					</div>
					<span class="list_arrow"></span>
				</div>
			</div>
			<!-- //공감영역 -->
		</div>
		<div class="face_list">
			<!-- 페이스공감리스트 -->
			<div
				v-if="items_emoji.length > 0"
				class="face_add_list"
			>
				<ul>
					<li
						v-for="(emoji, index) in list_emoji"
						:key="'emoji_' + index"
						class="emotion_member_item">
						<a>
							<span class="u_profile">
								<img
									v-if="emoji.member_profle_nft_card_img_url"
									:src="$request.upload_url(emoji.member_profle_nft_card_img_url)" alt="사진없음"
								>
								<img
									v-else
									:src="require('@/assets/image/@profile_inner.png')" alt="사진없음"
								>
							</span>
							<span class="u_faceicon faceicon_20 " :class="'f_' + emoji.type + '_20'"></span>
						</a>
					</li>
				</ul>
				<button
					v-if="items_emoji.length > 0"
					@click="is_059 = true"
					class="more_viewface" style="right: -5px"
				>
					<v-icon>mdi-chevron-right</v-icon>
				</button>
			</div>
			<!-- //페이스공감리스트 -->
			<!-- 페이스공감 초기화면 -->
			<span
				v-else
				class="emotion_message  size-px-16"
			>첫 공감을 눌러주세요.</span>
			<!-- 페이스공감 초기화면 -->
		</div>
		<mafia059
			v-if="is_059"

			:user="user"
			:bbs="bbs"
			@cancel="is_059 = false"

			style="position: fixed; width: 100%; height: 100%; left: 0; top: 0; z-index: 9999; background-color: white"
		></mafia059>
	</div>
</template>

<script>
	import mafia059 from '@/view/Cartel/mafia059'
	export default {
		name: 'mafia_emoji'
		, components: {mafia059}
		, props:['user', 'cartel', 'bbs', 'list']
		, data: function(){
			return {
				is_emoji: false
				, icons_emoji: [
					{ code: 'CA01100001', type: 'best', txt: '최고예요'}
					, { code: 'CA01100002', type: 'funny', txt: '웃겨요'}
					, { code: 'CA01100003', type: 'like', txt: '좋아요'}
					, { code: 'CA01100004', type: 'sad', txt: '슬퍼요'}
					, { code: 'CA01100005', type: 'surprise', txt: '놀랐어요'}
					, { code: 'CA01100006', type: 'angry', txt: '화나요'}
				]
				, items_emoji: []
				, is_059: false
			}
		}
		, computed: {
			list_emoji: function(){
				let self = this
				return this.items_emoji.filter(function(item){
					for(let t of self.icons_emoji){
						if(item.sympaty_icon_code == t.code){
							item.type = t.type
						}
						if(item.sympaty_member_profle_nft_card_img_url){
							item.member_profle_nft_card_img_url = item.sympaty_member_profle_nft_card_img_url
						}
					}

					return item
				})
			}
		}
		, methods: {
			postEmoji: async function(emoji){
				try{
					const result = await this.$request.init({
						method: 'post'
						, url: this.$api_url.api_path.post_emoji
						, data: {
							member_number: this.user.member_number
							, cartl_number: this.$route.params.idx
							, board_number: this.$route.params.b_id ? this.$route.params.b_id : this.bbs.board_number
							, bulletin_number: this.$route.params.bbs_id ? this.$route.params.bbs_id : this.bbs.bulletin_number
							, sympaty_icon_code: emoji.code
						}
						, type: true
					})

					if(result.success){
						this.$emit('click', emoji.code)
						await this.getEmojiList()
					}else{
						throw result.message
					}
				}catch (e) {
					console.log(e)
					this.$bus.$emit('notify', { type: 'error', message: e})
				}finally {
					this.is_emoji = false
					this.$bus.$emit('on', false)
				}
			}
			, getEmojiList: async function(){
				try{
					const result = await this.$request.init({
						method: 'post'
						, url: this.$api_url.api_path.get_emoji_list
						, data: {
							member_number: this.user.member_number
							, cartl_number: this.$route.params.idx
							, board_number: this.bbs.board_number
							, bulletin_number: this.bbs.bulletin_number
						}
						, type: true
					})

					if(result.success){
						this.items_emoji = result.data.sympaty_list
					}else{
						throw result.message
					}
				}catch (e) {
					console.log(e)
					this.$bus.$emit('notify', { type: 'error', message: e})
				}finally {
					this.$bus.$emit('on', false)
				}
			}
		}
		, created() {
			this.items_emoji = this.list
			if(this.list.length > 0){
				this.items_emoji = this.list
			}else{
				//this.getEmojiList()
			}
		}
	}
</script>